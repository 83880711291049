import { Close, Send } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { useState, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ModuleContext } from "../context/ModuleContext";
import { UserModuleContext } from "../context/UserModuleContext";

export const UnlockAccount = () => {

    const [state, setState] = useState({error: null, waiting: false});

    const {setModule} = useContext(ModuleContext);

    const {userModule, setUserModule} = useContext(UserModuleContext);
    
    const {authState} = useOktaAuth();

    const unlockAccount = async() => {

        setState((prev) => ({...prev, triggered: true, waiting: true}));

        await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/account/unlock`,{
            body: JSON.stringify({
                oktaId: userModule.userData["Okta ID"],
                comment: `Account unlocked`,
                callerId: authState.accessToken.claims.sub
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            }

        })
        .then((res) => res.json())
        .then((result) => {
            setState((prev) => ({...prev, waiting: false}));
            let newUserData = userModule.userData;
            newUserData["Okta Status"] = "PASSWORD_EXPIRED";
            setUserModule((prev) => ({...prev, userData: newUserData}));
        },
        (error) => {
            console.error(error);
            setState((prev) => ({...prev, waiting: false, result: "Attempt to suspend account failed"}));
        })

    }
    
    if(state.waiting){
        return(
            <div id="container-div"><CircularProgress key="account-status-loading" id="progress" size={100} thickness={10}/></div>
        );
    }else if(state.error){
        return(<div id="container-div">
            <p>{state.error.message}</p>
        </div>);
    }else if(userModule.userData["Okta Status"] === "ACTIVE"){
        return(
            <div key="active-status-container" id="container-div">
            <p key="suspend-account-header">{`Account ${userModule.userData["Network ID"]} is not locked.  No further action to be taken.`}</p><br />
            </div>
        );
    }else if(userModule.userData["Okta Status"] === "LOCKED_OUT"){
        return(
            <div key="suspended-status-container" id="container-div">
                <p key="unsuspend-account-header">{`Unlock account ${userModule.userData["Network ID"]}?`}</p><br />
                <Button key={userModule.userData["Okta ID"] + "-unsuspend-account"} variant="contained" id="left-button" endIcon={<Send />} onClick={(e) => unlockAccount()}>Yes</Button>
                <Button key={userModule.userData["Okta ID"] + "-dont-unsuspend"} variant="contained" id="right-button" endIcon={<Close />} onClick={(e) => setModule((prev) => ({...prev, isVisible: false}))}>No</Button>
            </div>
        );
    }else{
        return(
            <div key="not-in-status-container" id="container-div">
            <p key={userModule.userData["Okta ID"] + "-user-cant-action"}>{`User ${userModule.userData["Network ID"]} is in account status ${userModule.userData["Okta Status"]} and is not eligible for this action`}</p>
        </div>
        );
    }

}